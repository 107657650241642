import * as React from "react";
import { CircularProgress } from "@mui/material";
import { colors } from "../../styles/colors";
import useSignIn from "../../hooks/sign-in/useSignIn";
import AboutSidebar from "../../components/sign-in/about-sidebar";
import LoginContainer from "../../components/sign-in/login-container";
import LoginForm from "../../components/sign-in/login-form";
import PlaySvg from "../../assets/Play";
import LogoSvg from "../../assets/Logo";
import GoogleSvg from "../../assets/Google";
import DrawnArrowSvg from "../../assets/DrawnArrow";
import MascotSvg from "../../assets/Mascot";

const SignIn = () => {
  const { router } = useSignIn();

  return (
    <LoginContainer>
      <AboutSidebar
        title="Backoffice application"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a efficitur urna, a maximus eros. Nam ac elit magna. "
        icon={<PlaySvg color={colors.background} />}
        illustrationOne={<DrawnArrowSvg width={200} />}
        illustrationTwo={<MascotSvg width="100%" />}
      />

      <LoginForm
        title="Login"
        logo={<LogoSvg width={80} />}
        loader={<CircularProgress color={"secondary"} />}
        rememberMeText="Remember me for 30 days"
        forgotPswText="Forgot Password?"
        forgotPswClick={() => router.push("/reset-password-email")}
        loginText="Login"
        loginGoogleIcon={<GoogleSvg width={20} />}
        loginGoogleText="Login with Google"
      />
    </LoginContainer>
  );
};

export default SignIn;
