import * as React from "react";

const ProfileSvg = ({ color = "white" }: any) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25 8C7.25 5.92893 8.92893 4.25 11 4.25C13.0711 4.25 14.75 5.92893 14.75 8C14.75 10.0711 13.0711 11.75 11 11.75C8.92893 11.75 7.25 10.0711 7.25 8ZM11 5.75C9.75736 5.75 8.75 6.75736 8.75 8C8.75 9.24264 9.75736 10.25 11 10.25C12.2426 10.25 13.25 9.24264 13.25 8C13.25 6.75736 12.2426 5.75 11 5.75Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.25 11C0.25 5.06294 5.06294 0.25 11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11ZM11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 13.5456 2.77827 15.851 4.4421 17.5235C4.6225 16.5504 4.97694 15.6329 5.68837 14.8951C6.75252 13.7915 8.45416 13.25 11 13.25C13.5457 13.25 15.2474 13.7915 16.3115 14.8951C17.023 15.6329 17.3774 16.5505 17.5578 17.5236C19.2217 15.8511 20.25 13.5456 20.25 11C20.25 5.89137 16.1086 1.75 11 1.75ZM16.1937 18.6554C16.0918 17.4435 15.8286 16.5553 15.2318 15.9363C14.5823 15.2628 13.3789 14.75 11 14.75C8.62099 14.75 7.41761 15.2628 6.76815 15.9363C6.17127 16.5553 5.90811 17.4434 5.80622 18.6553C7.28684 19.6618 9.07473 20.25 11 20.25C12.9252 20.25 14.7131 19.6618 16.1937 18.6554Z"
      fill={color}
    />
  </svg>
);

export default ProfileSvg;
