export const swrCommandConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  revalidateOnMount: false,
  revalidateIfStale: false,
};

export const swrSingleGetConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
  revalidateOnMount: true,
  revalidateIfStale: true,
};

export const errorHandler = async (response: Response) => {
  if (response.ok == false) {
    if (response.status == 401) {
      const error = {
        title: "Client Error",
        status: 401,
        message: "Unauthorized",
        errors: ["Unauthorized"],
      };
      throw error;
    } else {
      const error = await response.json();
      throw error;
    }
  }

  return await response.json();
};
