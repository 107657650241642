import * as React from "react";

const LockSvg = ({ color = "white", padding = 0, margin = 0 }: any) => (
  <svg
    style={{ padding, margin }}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.25 8.30277V7C4.25 3.27208 7.27208 0.25 11 0.25C14.7279 0.25 17.75 3.27208 17.75 7V8.30277C17.9768 8.31872 18.1906 8.33948 18.3918 8.36652C19.2919 8.48754 20.0497 8.74643 20.6517 9.34835C21.2536 9.95027 21.5125 10.7081 21.6335 11.6082C21.75 12.4752 21.75 13.5775 21.75 14.9451V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H6.94513C5.57754 21.75 4.47522 21.75 3.60825 21.6335C2.70814 21.5125 1.95027 21.2536 1.34835 20.6516C0.746434 20.0497 0.487541 19.2919 0.366524 18.3918C0.249964 17.5248 0.24998 16.4225 0.250001 15.0549V14.9451C0.24998 13.5775 0.249964 12.4752 0.366524 11.6082C0.487541 10.7081 0.746434 9.95027 1.34835 9.34835C1.95027 8.74643 2.70814 8.48754 3.60825 8.36652C3.80938 8.33948 4.02317 8.31872 4.25 8.30277ZM5.75 7C5.75 4.10051 8.10051 1.75 11 1.75C13.8995 1.75 16.25 4.10051 16.25 7V8.25344C15.8765 8.24999 15.4784 8.24999 15.0549 8.25H6.94513C6.52161 8.24999 6.12353 8.24999 5.75 8.25344V7ZM2.40901 10.409C2.68577 10.1322 3.07435 9.9518 3.80812 9.85315C4.56347 9.75159 5.56459 9.75 7 9.75H15C16.4354 9.75 17.4365 9.75159 18.1919 9.85315C18.9257 9.9518 19.3142 10.1322 19.591 10.409C19.8678 10.6858 20.0482 11.0743 20.1469 11.8081C20.2484 12.5635 20.25 13.5646 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H7C5.56459 20.25 4.56347 20.2484 3.80812 20.1469C3.07435 20.0482 2.68577 19.8678 2.40901 19.591C2.13225 19.3142 1.9518 18.9257 1.85315 18.1919C1.75159 17.4365 1.75 16.4354 1.75 15C1.75 13.5646 1.75159 12.5635 1.85315 11.8081C1.9518 11.0743 2.13225 10.6858 2.40901 10.409Z"
      fill={color}
    />
  </svg>
);

export default LockSvg;
