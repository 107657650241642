import { Container, Box, Description, TitleContainer, Title, IconCircle, Illustrations } from "./style";

type AboutSidebarProps = {
  title: string;
  icon: React.ReactNode;
  description?: string;
  illustrationOne?: React.ReactNode;
  illustrationTwo?: React.ReactNode;
};

const AboutSidebar = (props: AboutSidebarProps) => {
  const { title, icon, description, illustrationOne, illustrationTwo } = props;
  return (
    <Container>
      <Box>
        <TitleContainer>
          <IconCircle>{icon}</IconCircle>
          <Title>{title}</Title>
        </TitleContainer>
        <Description>{description}</Description>
      </Box>
      <Illustrations>
        {illustrationOne}
        {illustrationTwo}
      </Illustrations>
    </Container>
  );
};

export default AboutSidebar;
