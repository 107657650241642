import {
  Container,
  Header,
  Title,
  Form,
  Footer,
  ForgotPswLink,
  CheckboxLabel,
  CheckboxText,
  LoginBtnPrimary,
  LoginBtnGoogle,
  Line,
  LineContainer,
  InnerBox,
} from "./style";
import React from "react";
import LockSvg from "../../../assets/Lock";
import ProfileSvg from "../../../assets/Profile";
import { TextField, InputAdornment, Checkbox } from "@mui/material";
import useSignIn from "../../../hooks/sign-in/useSignIn";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { colors } from "../../../styles/colors";

type LoginSidebarProps = {
  title: string;
  logo: React.ReactNode;
  loader: React.ReactNode;
  rememberMeText: string;
  forgotPswText: string;
  forgotPswClick: () => void;
  loginText: string;
  loginGoogleIcon: React.ReactNode;
  loginGoogleText: string;
};

const LoginForm = (props: LoginSidebarProps) => {
  const {
    title,
    logo,
    loader,
    rememberMeText,
    forgotPswText,
    forgotPswClick,
    loginText,
    loginGoogleIcon,
    loginGoogleText,
  } = props;
  const { isValidating, formik, showPassword, setShowPassword } = useSignIn();
  return (
    <Container>
      <InnerBox>
        <Header>
          <Title>{title}</Title>
          {logo}
        </Header>
        {isValidating ? (
          loader
        ) : (
          <Form as={"form"} onSubmit={formik.handleSubmit}>
            <TextField
              required
              variant="outlined"
              color={"secondary"}
              id="username"
              name="username"
              placeholder="Write your username"
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              sx={{ background: colors.background }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ProfileSvg />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              required
              variant="outlined"
              id="password"
              name="password"
              color={"secondary"}
              placeholder="Write password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{ background: colors.background }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockSvg />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{ cursor: "pointer" }}
                    position="end"
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }}
            />

            <Footer>
              <ForgotPswLink as={"button"} onClick={forgotPswClick}>
                {forgotPswText}
              </ForgotPswLink>
            </Footer>
            <LoginBtnPrimary as={"button"} type="submit">
              {loginText}
            </LoginBtnPrimary>
          </Form>
        )}
        <LineContainer>
          <Line /> Or <Line />
        </LineContainer>
        <LoginBtnGoogle>
          {loginGoogleIcon}
          {loginGoogleText}
        </LoginBtnGoogle>
      </InnerBox>
    </Container>
  );
};

export default LoginForm;
