import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { fontSize, fontWeight } from "../../../styles/fonts";
import { media } from "../../../styles/mediaQueries";

export const Container = styled.div({
  height: "100%",
  width: "30%",
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: colors.cards,
  flexDirection: "column",
  alignItems: "center",
  [media.maxLarge]: {
    width: "100%",
    maxWidth: 768,
    backgroundColor: "transparent",
  },
});

export const Box = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: colors.primaryBackground,
  borderRadius: 15,
  padding: "1.2rem",
  margin: "1.2rem",
});

export const TitleContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1rem",
});

export const Title = styled.h6({
  color: colors.primary,
  fontSize: fontSize.h6,
  fontWeight: fontWeight.semibold,
});

export const IconCircle = styled.div({
  backgroundColor: colors.primary,
  height: "2rem",
  width: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 50,
  padding: "0.5rem",
});

export const Description = styled.p({
  marginTop: "1.1rem",
  color: colors.primary,
  fontSize: fontSize.body,
});

export const Illustrations = styled.div({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "70%",
  [media.maxLarge]: {
    display: "none",
  },
});
