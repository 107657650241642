import * as React from "react";

const GoogleSvg = ({ color = "white", width = "100%" }: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 23.384 24">
    <g id="google_3_" data-name="google (3)" transform="translate(-0.308)">
      <path
        id="Path_1743"
        data-name="Path 1743"
        d="M12.479,14.265V10.986H23.528a10.857,10.857,0,0,1,.164,1.979,10.824,10.824,0,0,1-2.84,7.669A10.973,10.973,0,0,1,12.483,24,12.169,12.169,0,0,1,.308,12,12.169,12.169,0,0,1,12.483,0a11.434,11.434,0,0,1,8.223,3.307L18.392,5.62a8.358,8.358,0,0,0-5.913-2.341A8.608,8.608,0,0,0,3.873,12a8.608,8.608,0,0,0,8.606,8.721,8.028,8.028,0,0,0,6.059-2.4,6.793,6.793,0,0,0,1.777-4.059Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default GoogleSvg;
