import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { media } from "../../../styles/mediaQueries";

export const Container = styled.div({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: colors.background,
  [media.maxLarge]: {
    flexDirection: "column",
  },
});
