import { Container } from "./style";

type LoginProps = {
  children: React.ReactNode;
};

const LoginContainer = (props: LoginProps) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

export default LoginContainer;
