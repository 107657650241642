import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { fontSize, fontWeight } from "../../../styles/fonts";
import { media } from "../../../styles/mediaQueries";

export const Container = styled.div({
  display: "flex",
  width: "100%",
  justifyContent: "center",
});

export const InnerBox = styled.div({
  backgroundColor: colors.cards,
  maxWidth: 768,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "3rem",
  borderRadius: 15,
  [media.maxLarge]: {
    maxWidth: "100%",
  },
});

export const Header = styled.div({
  display: "flex",
  [media.maxSmall]: {
    flexDirection: "column-reverse",
    gap: "1.4rem",
  },
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export const Title = styled.h2({
  fontSize: fontSize.h2,
  color: colors.text,
});

export const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  marginTop: "2.4rem",
  width: "100%",
});

export const Footer = styled.div({
  [media.maxSmall]: {
    flexDirection: "column",
    gap: "1.4rem",
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const ForgotPswLink = styled.button({
  color: colors.primary,
  fontSize: fontSize.body,
  fontWeight: fontWeight.semibold,
});

export const CheckboxLabel = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const CheckboxText = styled.p({
  fontSize: fontSize.body,
  color: colors.text,
});

export const LoginBtnPrimary = styled.button({
  backgroundColor: colors.primary,
  width: "100%",
  height: 60,
  borderRadius: 50,
  color: colors.background,
  fontWeight: fontWeight.semibold,
  marginTop: "1.4rem",
});

export const LineContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: fontSize.body,
  color: colors.textGrey,
});

export const Line = styled.hr({
  borderTop: `1px solid ${colors.textGrey}`,
  width: "16rem",
  [media.maxMedium]: {
    width: "5rem",
  },
  margin: "2rem",
  alignItems: "flex-start",
});

export const LoginBtnGoogle = styled.button({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.7rem",
  border: `1px solid ${colors.text}`,
  width: "100%",
  height: 60,
  borderRadius: 50,
  color: colors.text,
});
