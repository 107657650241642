import * as React from "react";

const DrawnArrowSvg = ({ color = "white", width = "100%" }: any) => (
  <svg width={width} viewBox="0 0 213 228" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8108 151.688C7.14397 156.76 6.8017 164.144 5.10741 170.005C4.54294 171.414 6.68433 172.599 7.64347 171.021C10.1831 165.499 12.6108 158.623 13.3468 152.704C13.235 151.351 11.5443 150.674 10.8108 151.688Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.9661 120.922C26.4406 123.287 23.1116 129.147 21.3613 134.332C20.9098 135.46 22.6005 136.137 23.052 135.01C24.4628 132.305 26.0424 129.995 27.9038 127.797C29.6523 125.882 31.5697 124.361 33.3182 122.445C34.2204 121.826 33.1502 120.416 31.9661 120.922Z"
      fill="#6B6B6B"
    />
    <path
      d="M65.7993 100.14C62.8116 100.251 59.6542 101.602 57.0604 103.179C54.2976 104.362 51.5909 106.22 49.2788 107.91C48.3766 108.53 49.165 109.827 50.0673 109.207C52.6611 107.63 55.4239 106.448 58.0737 105.547C60.8365 104.365 63.937 103.972 66.6997 102.79C68.1654 102.397 67.4338 100.142 65.7993 100.14Z"
      fill="#6B6B6B"
    />
    <path
      d="M85.528 101.504C83.7804 101.785 84.1171 104.209 85.8649 103.928C87.4996 103.929 89.529 103.761 91.1637 103.762C93.362 103.988 95.2214 105.061 97.2508 104.893C98.209 104.95 98.7735 103.541 98.041 102.92C95.506 100.269 89.305 101.055 85.528 101.504Z"
      fill="#6B6B6B"
    />
    <path
      d="M123.799 108.008C120.981 106.879 118.163 105.75 115.401 105.297C113.879 105.015 113.202 106.705 114.611 107.27C117.034 108.568 119.288 109.471 121.825 110.487C124.361 111.503 126.671 113.083 129.546 113.253C130.785 113.423 131.575 111.45 130.562 110.717C128.533 109.25 126.166 108.629 123.799 108.008Z"
      fill="#6B6B6B"
    />
    <path
      d="M145.887 124.705C148.253 126.961 150.619 129.217 152.759 132.036C154.731 134.461 156.477 137.45 159.182 138.861C160.478 139.707 161.55 137.847 161.325 136.776C160.256 133.732 157.326 131.249 154.959 128.994C152.706 126.455 150.227 124.482 147.635 122.789C146.057 121.83 144.59 123.859 145.887 124.705Z"
      fill="#6B6B6B"
    />
    <path
      d="M162.504 146.078C163.798 148.559 165.376 151.153 166.389 153.521C166.896 154.705 167.29 156.171 167.402 157.524C167.514 158.877 167.288 159.44 168.076 160.737C168.696 161.64 170.049 161.528 170.669 160.795C172.249 158.485 170.616 155.215 169.603 152.846C168.138 149.971 166.167 147.546 163.914 145.008C163.181 144.387 161.884 145.176 162.504 146.078Z"
      fill="#6B6B6B"
    />
    <path
      d="M160.856 170.598C161.418 172.459 161.586 174.488 160.909 176.179C160.345 177.588 159.385 179.165 160.061 180.744C160.399 181.534 161.244 181.872 162.033 181.534C165.98 179.845 164.293 172.629 162.716 170.035C162.097 169.133 160.631 169.526 160.856 170.598Z"
      fill="#6B6B6B"
    />
    <path
      d="M147.713 186.261C146.528 188.403 144.948 190.713 143.199 192.629C141.057 194.713 138.633 195.05 135.814 195.556C134.348 195.95 133.952 197.753 135.53 198.712C143.025 202.042 148.273 193.026 149.685 187.052C149.742 186.094 148.051 185.416 147.713 186.261Z"
      fill="#6B6B6B"
    />
    <path
      d="M123.47 192.9C118.34 193.461 114.339 191.204 109.492 190.243C108.253 190.073 107.575 191.764 108.589 192.497C113.041 195.262 118.677 195.885 123.751 194.647C124.822 194.423 124.711 193.069 123.47 192.9Z"
      fill="#6B6B6B"
    />
    <path
      d="M75.455 172.355C78.2133 179.346 84.8068 183.296 91.4003 185.611C92.5274 186.062 93.3177 184.09 92.1905 183.638C86.3859 180.986 81.1458 176.924 77.8236 171.342C77.1477 169.763 74.7796 170.776 75.455 172.355Z"
      fill="#6B6B6B"
    />
    <path
      d="M61.7146 147.883C62.2186 153.971 65.4284 159.835 68.9769 164.854C69.7654 166.151 71.6828 164.63 70.7814 163.615C67.9094 158.54 66.2213 152.959 64.1386 147.546C63.4631 145.967 61.6024 146.53 61.7146 147.883Z"
      fill="#6B6B6B"
    />
    <path
      d="M52.0315 124.71C50.451 128.655 52.0261 134.518 53.8279 138.183C54.5035 139.762 56.8716 138.749 56.196 137.17C55.3515 135.197 54.9018 133.054 54.7338 131.025C54.5658 128.996 54.7924 126.797 54.3427 124.655C54.1178 123.584 52.3702 123.865 52.0315 124.71Z"
      fill="#6B6B6B"
    />
    <path
      d="M55.3245 82.209C54.4213 84.4633 54.3635 87.0562 53.7422 89.4234C53.1768 92.4671 52.3297 95.3978 52.4408 98.3854C52.4399 100.02 54.6381 100.247 54.9208 98.725C55.3733 95.9632 56.2204 93.0324 56.9547 90.3835C57.5761 88.0163 58.705 85.1984 58.0863 82.6615C58.0872 81.0268 55.8887 80.8005 55.3245 82.209Z"
      fill="#6B6B6B"
    />
    <path
      d="M62.5542 56.0012C60.2412 59.3257 59.2803 64.1729 57.9816 68.2308C57.4171 69.6397 59.3896 70.43 59.9541 69.0211C61.8164 65.189 64.3551 61.3009 65.0903 57.0172C65.3728 55.4958 63.4003 54.7056 62.5542 56.0012Z"
      fill="#6B6B6B"
    />
    <path
      d="M74.4622 30.3597C73.1084 32.1064 72.5999 34.1918 71.8095 36.1647C71.0194 38.1368 70.1161 40.3915 69.6076 42.4769C69.0431 43.8858 71.2974 44.7889 71.8619 43.38C72.6521 41.4075 73.5555 39.1528 74.6273 37.2937C75.4176 35.3212 76.4896 33.4616 76.7165 31.2628C77.1681 30.1357 75.4773 29.4583 74.4622 30.3597Z"
      fill="#6B6B6B"
    />
    <path
      d="M84.6773 7.31069C79.5465 9.50628 74.6975 11.8148 70.0175 14.5175C66.6343 16.4322 60.714 18.966 59.5281 22.7421C58.9637 24.151 60.2028 25.9555 61.9504 25.6746C65.4456 25.1129 68.7168 21.8452 71.4236 19.9865C74.9197 17.79 78.9794 15.8193 83.039 13.8486C83.0354 20.3875 83.8772 27.265 84.5501 33.7479C84.7181 35.7772 88.2693 35.8919 88.4962 33.6932C89.0642 25.7454 87.0393 17.7398 88.5656 9.84898C88.961 8.04583 86.538 6.74755 84.6773 7.31069Z"
      fill="#6B6B6B"
    />
  </svg>
);

export default DrawnArrowSvg;
