export const fontSize = {
  h1: 48,
  h2: 40,
  h3: 34,
  h4: 28,
  h5: 24,
  h6: 20,
  title: 16,
  body: 14,
  caption: 12,
};

export const fontWeight = {
  bold: "bold",
  semibold: "600",
  normal: "normal",
  thin: "400",
};
